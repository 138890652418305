#iv {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

ivion {
    min-height: 100%;
    width: 100%;
}

.panel-heading {
    color: white !important;
}

.threeD-overlay {
    max-width: 500px !important;
}

#left-panel-pois {
    background-color: rgba(0, 0, 0, 0) !important;
}

#poi-details-panel {
    background-color: rgba(58, 59, 62, .7) !important;
    color: white !important;
}

#floorChanger {
    background-color: rgba(58, 59, 62, .7) !important;
}

.btn {
    border-right: none !important;
}

.btn-group-vertical button {
    background-color: transparent!important;
    color: white !important;
}

button.active {
    color: #009fda !important;
}